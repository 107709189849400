import { withStyles } from '@material-ui/core/styles'
import TableCell from '@material-ui/core/TableCell'

const CustomCell = withStyles((theme) => ({
    root: {
        minWidth: 70,
        [theme.breakpoints.down('md')]: {
            width: 120,
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 45,
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
}))(TableCell)

const CustomNoBorderCell = withStyles((theme) => ({
    root: {
        borderBottom: 'none',
        verticalAlign: 'middle',
        minWidth: 70,
        [theme.breakpoints.down('md')]: {
            width: 120,
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 45,
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
}))(TableCell)

const CustomLimitWidthCell = withStyles((theme) => ({
    root: {
        borderBottom: 'none',
        verticalAlign: 'middle',
        minWidth: 70,
        [theme.breakpoints.down('md')]: {
            width: 120,
        },
        [theme.breakpoints.down('sm')]: {
            minWidth: 45,
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
        },
    },
}))(TableCell)

export { CustomCell, CustomNoBorderCell, CustomLimitWidthCell }
