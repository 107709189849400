import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import CustomStepper from './CustomStepper'

const ProgressBar = ({ activeStep, setActiveStep }) => {
    const { t } = useTranslation()
    const [progress, setProgress] = useState(0)

    const steps = [t('fetchingData'), t('fetchingBlockchainProof'), t('Verifying'), `${t('Verify done')}!`]

    const nextStep = () => {
        if (activeStep === 1 || activeStep === 3) {
            setActiveStep((prev) => prev + 1)
            setProgress(0)
        }
    }

    const randomBarValue = (timesValue, maxValue) => {
        if (progress < 90) {
            const timer = setTimeout(() => {
                setProgress((prev) => {
                    const diff = Math.random() * timesValue
                    return Math.min(prev + diff, maxValue)
                })
            }, 100)

            return () => {
                clearTimeout(timer)
            }
        } else if (progress > 90) {
            const timer = setTimeout(() => {
                nextStep()
            }, 100)

            return () => {
                clearTimeout(timer)
            }
        }
    }

    useEffect(() => {
        // progressBar value
        if (activeStep <= 3) {
            if (activeStep === 1) {
                randomBarValue(6, 100)
            } else if (activeStep === 2) {
                randomBarValue(2, 90)
            } else {
                randomBarValue(10, 100)
            }
        }
    }, [progress])

    return <CustomStepper steps={steps} activeStep={activeStep} progress={progress} />
}

ProgressBar.propTypes = {
    activeStep: PropTypes.number.isRequired,
    setActiveStep: PropTypes.func.isRequired,
}

export default ProgressBar
