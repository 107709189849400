import { createTheme } from '@material-ui/core/styles'

const theme = {
    overrides: {
        MuiTable: {
            root: {
                border: '1px solid #56F7FD',
                borderRadius: '8px',
            },
        },
        MuiTableCell: {
            root: {
                borderBottom: '1px solid #56F7FD',
            },
            stickyHeader: {
                backgroundColor: 'transparent',
            },
        },
        MuiTablePagination: {
            root: {
                '&:last-child': {
                    border: 'none',
                },
            },
        },
        MuiSelect: {
            icon: {
                color: '#FFFFFF',
            },
        },
        MuiButton: {
            root: {
                textTransform: 'capitalize',
            },
            containedSecondary: {
                '&:hover': {
                    backgroundColor: 'rgba(2, 183, 187, 0.6)',
                },
            },
        },
        MuiIconButton: {
            root: {
                color: '#FFFFFF',
                '&$disabled': {
                    color: '#FFFFFF',
                    opacity: 0.4,
                },
                '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
            },
        },
        MuiDrawer: {
            paperAnchorBottom: {
                height: '100%',
            },
        },
        MuiLinearProgress: {
            barColorPrimary: {
                backgroundColor: '#02B7BB',
            },
        },
    },
    palette: {
        default: {
            main: '#A3A3A3',
        },
        primary: {
            main: '#133751',
            contrastText: '#FFFFFF',
        },
        secondary: {
            light: '#56F7FD',
            main: '#02B7BB',
            dark: '#28585A',
            contrastText: '#FFFFFF',
        },
        error: {
            main: '#FF5757',
        },
        warning: {
            main: '#FCBA03',
        },
        success: {
            main: '#77C95F',
        },
        text: {
            primary: '#FFFFFF',
            hint: '#A3A3A3',
        },
        background: {
            paper: '#133751',
            default: '#133751',
        },
        // action: {
        //     active: '',
        //     hover: '',
        //     hoverOpacity: '',
        // },
    },
    typography: {
        fontFamily: '"Noto Sans TC", sans-serif',
    },
}

export default createTheme(theme)
