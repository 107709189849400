import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import TableRow from '@material-ui/core/TableRow'
import Tooltip from '@material-ui/core/Tooltip'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import GavelIcon from '@material-ui/icons/Gavel'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorCircleIcon from '@material-ui/icons/Error'

import VerifyStatus from '../VerifyStatus'
import { CustomCell } from '../../../style/CustomTableCell'
import proofExistenceType from '../../../constant/proofExistenceType'
import verifyProcessStatusConstant from '../../../constant/verifyProcessStatusConstant'
import { getAttesterDID, getAttestType, getMIHAttesterInfo, renderTime } from '../../../util/stringUtil'

const useStyles = makeStyles((theme) => ({
    certificatePass: {
        marginLeft: theme.spacing(1),
    },
    certificateFail: {
        color: theme.palette.text.hint,
        marginLeft: theme.spacing(1),
    },
    circularProgress: {
        color: theme.palette.secondary.light,
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
}))

const DataRow = ({ data, renderMoreInfo, handleVerifyRawData, handleDownloadRawData, renderClearanceOrder }) => {
    const { t } = useTranslation()
    const classes = useStyles()

    const renderCOAndLimitLengthIndexValue = (data) => {
        const iv = data.indexValue
        return (
            <>
                <Hidden mdDown>
                    <CustomCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Typography variant="body2" className={classes.marginTop}>
                            {iv}
                        </Typography>
                    </CustomCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                    <CustomCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Tooltip title={iv} placement="bottom">
                            <span>
                                <Typography variant="body2" className={classes.marginTop}>
                                    {`${iv.substr(0, 5)}...${iv.substr(iv.length - 5)}`}
                                </Typography>
                            </span>
                        </Tooltip>
                    </CustomCell>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <CustomCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Tooltip title={iv} placement="bottom">
                            <span>
                                <Typography variant="body2" className={classes.marginTop}>
                                    {`${iv.substr(0, 3)}...${iv.substr(iv.length - 3)}`}
                                </Typography>
                            </span>
                        </Tooltip>
                    </CustomCell>
                </Hidden>
            </>
        )
    }

    // get data
    const attesterDID = getAttesterDID(data.cmd)
    const attestType = getAttestType(data.cmd)
    const attesterInfo = getMIHAttesterInfo(data.cmd)

    return (
        <TableRow id="verify-list-result-row" key={uuidv4()}>
            <CustomCell align="left">
                <Hidden only={['xs']}>
                    <Typography variant="body2">
                        {data.existenceType === proofExistenceType.exist && renderTime(data.receiptTimestamp)}
                    </Typography>
                    <Typography variant="body2" className={classes.marginTop}>
                        {data.existenceType === proofExistenceType.exist && attestType}
                    </Typography>
                </Hidden>
                <Hidden smUp>
                    <Typography variant="body2">
                        {data.existenceType === proofExistenceType.exist && renderTime(data.receiptTimestamp)}
                    </Typography>
                    {attestType && attestType !== '' && <Typography variant="body2">{t(attestType)}</Typography>}
                </Hidden>
            </CustomCell>
            <Hidden mdDown>
                <CustomCell align="left">
                    {data.existenceType === proofExistenceType.exist && (
                        <>
                            {<Typography variant="body2">{attesterDID ? attesterDID : ''}</Typography>}
                            <Box display="flex" alignItems="center" className={classes.marginTop}>
                                {attesterInfo ? t(attesterInfo) : t('Not Certified')}
                                {data.cmd && data.cmd.includes('attestDID') ? (
                                    <CheckCircleIcon
                                        fontSize="small"
                                        color="secondary"
                                        className={classes.certificatePass}
                                    />
                                ) : (
                                    <ErrorCircleIcon fontSize="small" className={classes.certificateFail} />
                                )}
                            </Box>
                        </>
                    )}
                </CustomCell>
            </Hidden>
            <Hidden only={['xs']}>{renderCOAndLimitLengthIndexValue(data)}</Hidden>
            <CustomCell align="center">
                <VerifyStatus status={data.proofExistStatus} iconOnly={true} />
            </CustomCell>
            <Hidden smDown>
                <CustomCell align="center">
                    {data.existenceType === proofExistenceType.exist && (
                        <>
                            {data.verifyProcessStatus === verifyProcessStatusConstant.pending && (
                                <CircularProgress size={24} className={classes.circularProgress} />
                            )}
                            {data.verifyProcessStatus === verifyProcessStatusConstant.complete && (
                                <VerifyStatus status={data.rawDataVerifyResult.verifyResult} iconOnly={true} />
                            )}
                            {!data.verifyProcessStatus && (
                                <IconButton onClick={() => handleVerifyRawData(data)}>
                                    <GavelIcon fontSize="small" />
                                </IconButton>
                            )}
                        </>
                    )}
                </CustomCell>
                <CustomCell align="center">
                    {data.existenceType === proofExistenceType.exist && (
                        <IconButton onClick={() => handleDownloadRawData(data)}>
                            <GetAppIcon />
                        </IconButton>
                    )}
                </CustomCell>
            </Hidden>
            <CustomCell align="center">{renderMoreInfo(data)}</CustomCell>
        </TableRow>
    )
}

DataRow.propTypes = {
    data: PropTypes.object.isRequired,
    renderMoreInfo: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
    renderClearanceOrder: PropTypes.func.isRequired,
}

export default DataRow
