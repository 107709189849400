import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Tooltip from '@material-ui/core/Tooltip'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import RemoveIcon from '../../img/remove.svg'
import AddIcon from '../../img/plus.svg'
import Modify from '../../img/modify.svg'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    checkIcon: {
        color: theme.palette.secondary.light,
    },
    checkIconLarge: {
        color: theme.palette.secondary.light,
        fontSize: '1.8rem',
    },
    iconSize: {
        fontSize: '1.8rem',
    },
    marginLeft: {
        marginLeft: theme.spacing(0.5),
    },
}))

const VerifyStatus = ({ status, iconOnly }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            {status === 'PASS' &&
                (iconOnly ? (
                    <Tooltip title={t('Pass')} placement="right">
                        <CheckIcon className={classes.checkIconLarge} />
                    </Tooltip>
                ) : (
                    <Box display="flex" alignItems="center">
                        <CheckIcon className={classes.checkIcon} />
                        <Typography variant="body2" className={classes.marginLeft}>
                            {t('Pass')}
                        </Typography>
                    </Box>
                ))}
            {status === 'CLEARANCE_RECORD_ERROR' &&
                (iconOnly ? (
                    <Tooltip title={t('Clearance record error')} placement="right">
                        <CloseIcon color="error" className={classes.iconSize} />
                    </Tooltip>
                ) : (
                    <Box display="flex" alignItems="center">
                        <CloseIcon color="error" />
                        <Typography variant="body2" className={classes.marginLeft}>
                            {t('Clearance record error')}
                        </Typography>
                    </Box>
                ))}
            {/* TODO check for the status below */}
            {status === 'REMOVED' && (
                <Tooltip title={t('Removed')} placement="right">
                    <img width="30" alt="remove" src={RemoveIcon} />
                </Tooltip>
            )}
            {status === 'ADDED' && (
                <Tooltip title={t('Added')} placement="right">
                    <img width="30" alt="add" src={AddIcon} />
                </Tooltip>
            )}
            {status === 'MODIFIED' && (
                <Tooltip title={t('Modified')} placement="right">
                    <img height="30" alt="result" src={Modify} />
                </Tooltip>
            )}
        </>
    )
}

VerifyStatus.propTypes = {
    status: PropTypes.string.isRequired,
    iconOnly: PropTypes.bool.isRequired,
}

export default VerifyStatus
