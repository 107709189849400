import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { makeStyles, Box, Typography, IconButton, Modal, Collapse, Button } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import SwapVertIcon from '@material-ui/icons/SwapVert'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import RwdVerifyDetailTable from '../ProofVerificationDetailDrawer/RwdVerifyDetailTable'
import RwdVerifyListTable from '../ProofVerificationDetailDrawer/RwdVerifyListTable'
import RwdMoreInfoModal from '../ProofVerificationDetailDrawer/RwdMoreInfoModal'
import sortTypeConstant from '../../../../constant/sortTypeConstant'
// import { getTxHashBaseUrl } from '../../../../util/stringUtil'

const useStyles = makeStyles((theme) => ({
    marginRight: {
        marginRight: theme.spacing(0.5),
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
    sortButton: {
        textTransform: 'none',
    },
}))

const ProofVerificationDetailSection = ({
    detailData,
    modalOpen,
    moreInfo,
    handleVerifyRawData,
    handleDownloadRawData,
    handleMoreInfoModalOpen,
    handleMoreInfoModalClose,
    txHash,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const [detailExpand, setDetailExpand] = useState(true)
    const [listExpand, setListExpand] = useState(true)
    const [sortType, setSortType] = useState(sortTypeConstant.ASC)

    const onViewMintTxClick = () => {
        // const txHashBaseUrl = getTxHashBaseUrl(detailData.evmEnv)
        // const url = `${txHashBaseUrl}/${txHash}`
        // FIXME this is temp url for demo
        const url =
            'https://goerli.etherscan.io/token/0xd3db8136825271b5e830259a56803f7eec96f91d?a=48286979285775179171419453053081662591059086617845718646635967994807038156095'
        const link = document.createElement('a')
        link.href = url
        link.target = '_blank'
        link.rel = 'noopener noreferrer'
        link.click()

        console.log('env: ', detailData.evmEnv)
        console.log('link: ', link)
    }

    const onSortClick = () => {
        if (sortType === sortTypeConstant.ASC) {
            setSortType(sortTypeConstant.DESC)
        } else {
            setSortType(sortTypeConstant.ASC)
        }
    }

    return (
        <>
            {txHash && txHash !== '' && (
                <>
                    <Typography variant="h6" color="textPrimary">
                        {t('NFT Info')}
                    </Typography>
                    <Button
                        variant="contained"
                        color="secondary"
                        startIcon={<OpenInNewIcon />}
                        className={classes.marginTop}
                        onClick={onViewMintTxClick}
                    >
                        {t('View Mint Transaction On Chain')}
                    </Button>
                </>
            )}
            <Box display="flex" alignItems="center" mt={4} mb={2}>
                <Typography variant="h6" color="textPrimary" className={classes.marginRight}>
                    {t('Blockchain Verify Detail')}
                </Typography>
                <IconButton size="small" onClick={() => setDetailExpand((prev) => !prev)}>
                    {detailExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={detailExpand} timeout="auto" unmountOnExit>
                <RwdVerifyDetailTable detailData={detailData} />
            </Collapse>
            {detailData.verifyResultDescription !== 'Contract connection error' &&
                detailData.proofDetailList.length !== 0 && (
                    <Box mt={4}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={1}>
                            <Box display="flex" alignItems="center">
                                <Typography variant="h6" color="textPrimary" className={classes.marginRight}>
                                    {t('Verify List')}
                                </Typography>
                                <IconButton size="small" onClick={() => setListExpand((prev) => !prev)}>
                                    {listExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                </IconButton>
                            </Box>
                            <Button startIcon={<SwapVertIcon />} className={classes.sortButton} onClick={onSortClick}>
                                {sortType === sortTypeConstant.ASC ? t('oldestToNewest') : t('newestToOldest')}
                            </Button>
                        </Box>
                        <Collapse in={listExpand} timeout="auto" unmountOnExit>
                            <RwdVerifyListTable
                                detailData={detailData}
                                handleMoreInfoModalOpen={handleMoreInfoModalOpen}
                                handleVerifyRawData={handleVerifyRawData}
                                handleDownloadRawData={handleDownloadRawData}
                                sortType={sortType}
                            />
                        </Collapse>
                    </Box>
                )}
            <Modal open={modalOpen} onClose={handleMoreInfoModalClose}>
                <Box>
                    <RwdMoreInfoModal
                        close={handleMoreInfoModalClose}
                        moreInfo={moreInfo}
                        handleVerifyRawData={handleVerifyRawData}
                        handleDownloadRawData={handleDownloadRawData}
                    />
                </Box>
            </Modal>
        </>
    )
}

ProofVerificationDetailSection.propTypes = {
    detailData: PropTypes.object.isRequired,
    modalOpen: PropTypes.bool.isRequired,
    moreInfo: PropTypes.object.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
    handleMoreInfoModalOpen: PropTypes.func.isRequired,
    handleMoreInfoModalClose: PropTypes.func.isRequired,
    txHash: PropTypes.string,
}

export default ProofVerificationDetailSection
