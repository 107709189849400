import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'

import Navbar from './component/navbar/Navbar'
import ProofVerificationPage from './component/page/ProofVerification/ProofVerificationPage'
import theme from './component/theme/Theme'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    root: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
        },
    },
    content: {
        [theme.breakpoints.up('sm')]: {
            width: '100%',
        },
        padding: theme.spacing(3),
    },
}))

const App = () => {
    const classes = useStyles()
    const { i18n } = useTranslation()
    const cookies = new Cookies()

    useEffect(() => {
        if (cookies.get('language') === 'English') {
            i18n.changeLanguage('en')
        } else {
            cookies.set('language', '中文', { path: '/' })
            i18n.changeLanguage('zh-TW')
        }
    }, [])

    const isBiggerView = useMediaQuery(theme.breakpoints.up('sm'))

    return (
        <div className={classes.root}>
            <ThemeProvider theme={theme}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: isBiggerView ? 'top' : 'bottom',
                        horizontal: 'center',
                    }}
                    autoHideDuration={5000}
                >
                    <Router>
                        <Navbar />
                        <Box className={classes.content}>
                            <Toolbar />
                            <Switch>
                                <Route exact path="/nft-history">
                                    <ProofVerificationPage />
                                </Route>
                                <Route path="/nft-history/:tokenId">
                                    <ProofVerificationPage />
                                </Route>
                                <Route path="*">
                                    <Redirect to="/nft-history" />
                                </Route>
                            </Switch>
                        </Box>
                    </Router>
                </SnackbarProvider>
            </ThemeProvider>
        </div>
    )
}

export default App
