import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Hidden from '@material-ui/core/Hidden'
import Tooltip from '@material-ui/core/Tooltip'
import TableCell from '@material-ui/core/TableCell'
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore'
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess'

import VerifyStatus from '../VerifyStatus'
import { collapseBgcolor, hoverCollapseBgcolor } from '../../../style/colorStyle'

const useStyles = makeStyles((theme) => ({
    checkIconLarge: {
        color: theme.palette.success.main,
        fontSize: '1.8rem',
    },
    iconSize: {
        fontSize: '1.8rem',
    },
    tableCell: {
        padding: 0,
    },
    collapseRow: {
        backgroundColor: collapseBgcolor,
    },
    collapseCell: {
        padding: 0,
        borderBottom: (expand) => (expand ? `1px solid ${theme.palette.secondary.light}` : 'none'),
    },
    button: {
        flexGrow: 1,
        justifyContent: 'flex-start',
        backgroundColor: collapseBgcolor,
        '&:hover': { backgroundColor: hoverCollapseBgcolor },
        textTransform: 'none',
    },
}))

const CollapseRow = ({ dataArr, renderMoreInfo, renderClearanceOrder, hideBottomBorder }) => {
    const [expand, setExpand] = useState(false)
    const classes = useStyles(expand)
    const { t } = useTranslation()
    const isMdDown = useMediaQuery((theme) => theme.breakpoints.down('md'))

    const handleHiddenRowExpand = () => {
        setExpand((prev) => !prev)
    }

    const renderCOAndLimitLengthIndexValue = (data) => {
        const iv = data.indexValue
        return (
            <>
                <Hidden mdDown>
                    <TableCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Typography variant="body2" className={classes.marginTop}>
                            {iv}
                        </Typography>
                    </TableCell>
                </Hidden>
                <Hidden only={['xs', 'sm', 'lg', 'xl']}>
                    <TableCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Tooltip title={iv} placement="bottom">
                            <span>
                                <Typography variant="body2" className={classes.marginTop}>
                                    {`${iv.substr(0, 5)}...${iv.substr(iv.length - 5)}`}
                                </Typography>
                            </span>
                        </Tooltip>
                    </TableCell>
                </Hidden>
                <Hidden only={['md', 'lg', 'xl']}>
                    <TableCell align="left">
                        <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                        <Tooltip title={iv} placement="bottom">
                            <span>
                                <Typography variant="body2" className={classes.marginTop}>
                                    {`${iv.substr(0, 3)}...${iv.substr(iv.length - 3)}`}
                                </Typography>
                            </span>
                        </Tooltip>
                    </TableCell>
                </Hidden>
            </>
        )
    }

    // FIXME refactor border logic
    return (
        <>
            <TableRow id="expand-hidden-row" key={uuidv4()}>
                <TableCell
                    align="left"
                    colSpan={12}
                    className={hideBottomBorder ? classes.collapseCell : classes.tableCell}
                >
                    <Box display="flex">
                        <Button
                            startIcon={
                                expand ? <UnfoldLessIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />
                            }
                            className={classes.button}
                            onClick={handleHiddenRowExpand}
                        >
                            {expand ? t('Hide verify data hint') : t('Show hidden verify data hint')}
                        </Button>
                    </Box>
                </TableCell>
            </TableRow>
            {dataArr &&
                dataArr.map((data, index) => (
                    <React.Fragment key={`collapse-row-${index}`}>
                        {expand && (
                            <TableRow className={classes.collapseRow}>
                                <TableCell align="left" size="small" colSpan={isMdDown ? 1 : 2}>
                                    <Typography variant="body2">{t('End of record proof')}</Typography>
                                </TableCell>
                                <Hidden only={['xs']}>{renderCOAndLimitLengthIndexValue(data)}</Hidden>
                                <TableCell align="center" size="small">
                                    <VerifyStatus status={data.proofExistStatus} iconOnly={true} />
                                </TableCell>
                                <Hidden smDown>
                                    <TableCell align="center" size="small" />
                                    <TableCell align="center" size="small" />
                                </Hidden>
                                <TableCell align="center" size="small">
                                    {renderMoreInfo(data)}
                                </TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                ))}
        </>
    )
}

CollapseRow.propTypes = {
    dataArr: PropTypes.array.isRequired,
    renderMoreInfo: PropTypes.func.isRequired,
    renderClearanceOrder: PropTypes.func.isRequired,
    hideBottomBorder: PropTypes.bool,
}

export default CollapseRow
