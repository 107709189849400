import React from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'

import ITMIcon from '../../img/ITM.svg'
import LanguageMenu from './LanguageMenu'

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    paperButton: {
        textTransform: 'none',
        color: 'white',
        alignItems: 'center',
    },
    avatar: {
        width: '30px',
        height: '30px',
        marginLeft: theme.spacing(1),
    },
    logoText: {
        [theme.breakpoints.up('sm')]: {
            fontSize: theme.typography.h5.fontSize,
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: theme.typography.h6.fontSize,
        },
    },
}))

const Navbar = () => {
    const classes = useStyles()

    return (
        <>
            <AppBar color="primary" position="fixed" className={classes.appBar}>
                <Toolbar>
                    <Link to="/" style={{ textDecoration: 'none', color: 'black' }}>
                        <Button className={classes.paperButton}>
                            <img alt="itmLogo" src={ITMIcon} width="65" />
                            <Box m={2} ml={1}>
                                <Typography className={classes.logoText}>Verification</Typography>
                            </Box>
                        </Button>
                    </Link>
                    <Box ml="auto">
                        <LanguageMenu />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Navbar
