import moment from 'moment'
import proofExistenceType from '../constant/proofExistenceType'

const renderCertificatedName = (certification) => {
    const { certificatedChineseName, certificatedEnglishName } = certification
    if (
        certificatedChineseName &&
        certificatedChineseName !== '' &&
        certificatedEnglishName &&
        certificatedEnglishName !== ''
    ) {
        return `${certificatedChineseName} / ${certificatedEnglishName}`
    } else if (certificatedChineseName && certificatedChineseName !== '') {
        return certificatedChineseName
    } else if (certificatedEnglishName && certificatedEnglishName !== '') {
        return certificatedEnglishName
    }
}

const getAttesterDID = (cmd) => {
    let attesterDID = null
    let cmdObject = null
    let buyerDID = null
    if (cmd) {
        try {
            cmdObject = JSON.parse(cmd)
        } catch (error) {
            console.log('parse cmd error:', error)
        }
        attesterDID = cmdObject.attestDID
        buyerDID = cmdObject.buyerDID

        if (!attesterDID || attesterDID === '') {
            attesterDID = buyerDID
        }
    }
    return attesterDID
}

const getAttestType = (cmd) => {
    let cmdObject = null
    try {
        cmdObject = JSON.parse(cmd)
    } catch (error) {
        console.log('parse cmd error:', error)
    }
    if (cmdObject?.type) {
        return cmdObject.type
    } else {
        return null
    }
}

const getMIHAttesterInfo = (cmd) => {
    let attesterDID = null
    let cmdObject = null
    let attesterInfo = 'MIH Maintenance Center'
    if (cmd) {
        try {
            cmdObject = JSON.parse(cmd)
        } catch (error) {
            console.log('parse cmd error:', error)
        }
        attesterDID = cmdObject.attestDID

        if (attesterDID && attesterDID !== '') {
            if (
                attesterDID.toLocaleLowerCase() !==
                'did:mih:user:0xb4233b593b61bBb30a9f57E532F381fe0Ca2af2e'.toLocaleLowerCase()
            ) {
                attesterInfo = 'MIH Demo Car Owner'
            }
        }
    }
    return attesterInfo
}

const getTxHashBaseUrl = (evmEnv) => {
    let txHashBaseUrl = ''
    if (evmEnv === 'RINKEBY' || evmEnv === 'KOVAN' || evmEnv === 'GOERLI' || evmEnv === 'ROPSTEN') {
        txHashBaseUrl = `https://${evmEnv}.etherscan.io/tx`
    } else if (evmEnv === 'MAINNET') {
        txHashBaseUrl = `https://etherscan.io/tx`
    } else if (evmEnv === 'AZURE_QUORUM') {
        txHashBaseUrl = `http://itm-blockchain-explorer.itm.monster/transactions`
    }

    return txHashBaseUrl
}

const renderTime = (value) => {
    if (value) {
        return moment(value).format('YYYY/MM/DD HH:mm:ss')
    } else {
        return 'N/A'
    }
}

const getContractAddressUrl = (evmEnv, contractAddress) => {
    let url = ''
    if (evmEnv === 'RINKEBY' || evmEnv === 'KOVAN' || evmEnv === 'GOERLI' || evmEnv === 'ROPSTEN') {
        url = `https://${evmEnv}.etherscan.io/address/${contractAddress}`
    } else if (evmEnv === 'MAINNET') {
        url = `https://etherscan.io/address/${contractAddress}`
    } else if (evmEnv === 'AZURE_QUORUM') {
        url = `http://itm-blockchain-explorer.itm.monster/contracts/${contractAddress}`
    }
    return url
}

const renderExistence = (existenceType) => {
    if (existenceType === proofExistenceType.exist) {
        return 'O'
    } else if (existenceType === proofExistenceType.NA) {
        return 'N/A'
    } else if (existenceType === proofExistenceType.notExist) {
        return 'X'
    }
}

const renderCmd = (cmd) => {
    if (cmd) {
        const slice = cmd.slice(1).slice(0, -1)
        const result = `{\n\xa0 ${slice.split(',').join(',\n\xa0\xa0')} \n}`
        return result
    } else {
        return 'N/A'
    }
}

const getSn = (indexValue) => {
    return Number(indexValue.slice(indexValue.lastIndexOf('_R') + 2))
}

export {
    renderCertificatedName,
    getAttesterDID,
    getAttestType,
    getMIHAttesterInfo,
    getTxHashBaseUrl,
    renderTime,
    getContractAddressUrl,
    renderExistence,
    renderCmd,
    getSn,
}
