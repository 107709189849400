import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import verificationAxios from '../../../axios/verificationServer_https'
import mintServerAxios from '../../../axios/mintServerAxios'

import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Backdrop from '@material-ui/core/Backdrop'
import { Hidden } from '@material-ui/core'

import UploadSection from './UploadDetectedSection/UploadSection'
import RwdUploadSection from './UploadDetectedSection/RwdUploadSection'
import DetectedSection from './UploadDetectedSection/DetectedSection'
import ProgressBar from '../../common/StepperWithProgressBar/ProgressBar'
import ProofVerificationDetailSection from './ProofVerificationDetailSection/ProofVerificationDetailSection'
import verifyProcessStatusConstant from '../../../constant/verifyProcessStatusConstant'
import { getTxHashBaseUrl } from '../../../util/stringUtil'

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(3, 0),
        [theme.breakpoints.up('sm')]: {
            padding: theme.spacing(3, 2),
        },
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.text.primary,
        backgroundColor: 'rgb(0, 0, 0, 0.8)',
    },
    progressContainer: {
        [theme.breakpoints.up('md')]: {
            maxWidth: '65%',
        },
    },
}))

const ProofVerificationPage = () => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()
    let { tokenId } = useParams()
    const [open, setopen] = useState(false)

    const maxFileSize = 1000000000
    const [isUploader, setIsUploader] = useState(true)
    const [proofTokenInfo, setProofTokenInfo] = useState({ vin: null, fileName: '', downloadFileLink: '', txHash: '' })
    const [moreInfo, setMoreInfo] = useState({})
    const [moreInfoModalOpen, setMoreInfoModalOpen] = useState(false)
    const [detailData, setDetailData] = useState(null)

    // stepper with progress bar
    const [activeStep, setActiveStep] = useState(0)

    useEffect(() => {
        if (activeStep === 1) {
            setTimeout(() => {
                setActiveStep(3)
            }, 5000)

            setTimeout(() => {
                setopen(false)
            }, 6500)
        } else if (activeStep === 2) {
            setActiveStep(3)
            setTimeout(() => {
                setopen(false)
            }, 1500)
        }
    }, [detailData])

    useEffect(() => {
        setDetailData(null)
        if (tokenId && tokenId !== '') {
            setProofTokenInfo({ vin: null, fileName: '', downloadFileLink: '', txHash: '' })
            setIsUploader(false)
            fetchProofTokenInfo(tokenId)
        } else {
            setIsUploader(true)
        }
    }, [])

    const fetchFile = (url) => {
        return fetch(url, {
            method: 'GET',
        }).then((res) => {
            return res.blob()
        })
    }

    const fetchProofTokenInfo = async (tokenId) => {
        console.log('tokenId:', tokenId)
        setopen(true)
        mintServerAxios
            .get(`/rest/proof-token/${tokenId}`)
            .then((res) => {
                console.log('fetchProofTokenInfo() res: ', res)
                const proofFileName = res.data.proofTokenFileName
                const downloadProofTokenUrl = res.data.downloadProofTokenUrl
                const vin = res.data.vin
                const txHash = res.data.tokenTxHash
                if (downloadProofTokenUrl && proofFileName) {
                    setActiveStep(1)
                    fetchFile(downloadProofTokenUrl).then((blob) => {
                        let url = URL.createObjectURL(blob)
                        let link = document.createElement('a')
                        link.href = url
                        link.download = proofFileName
                        document.body.appendChild(link)
                        setProofTokenInfo({ vin, fileName: proofFileName, downloadFileLink: link, txHash })
                        const file = new File([blob], proofFileName)
                        handleVerify([file], vin)
                    })
                } else {
                    enqueueSnackbar(
                        `${t(
                            'Fail to find corresponding proof token. Please check if the data has been attested or try again later.',
                        )}`,
                        {
                            variant: 'error',
                        },
                    )
                    setIsUploader(true)
                    setopen(false)
                }
            })
            .catch((error) => {
                console.log('fetchProofTokenInfo() error:', error)
                enqueueSnackbar(
                    `${t(
                        'Fail to find corresponding proof token. Please check if the data has been attested or try again later.',
                    )}`,
                    {
                        variant: 'error',
                    },
                )
                setIsUploader(true)
                setopen(false)
            })
    }

    const handleVerify = (acceptedFiles, vin) => {
        const formData = new FormData()
        acceptedFiles.forEach((file) => {
            formData.append('verification-proof', file)
            setopen(true)
            setActiveStep(1)
            verificationAxios({
                method: 'post',
                url: '/verifyProof',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res) => {
                    console.log('handleVerify() res: ', res)
                    if (res.data.status === 'ok') {
                        const verifyResult = { ...res.data, vin }
                        // Item may exceed localStorage max size, don't save result for now
                        // verificationProof.push(verifyResult)
                        // localStorage.setItem('verification-proof', JSON.stringify(verificationProof))
                        setDetailData(verifyResult)
                    } else if (
                        res.data.status !== 'ok' &&
                        res.data.description &&
                        res.data.description.toLocaleLowerCase().includes('convert', 'fail')
                    ) {
                        enqueueSnackbar(
                            `${t('Fail to upload, file content should be in ITM proof token and JSON format.')}`,
                            {
                                variant: 'error',
                            },
                        )
                        setopen(false)
                    } else {
                        enqueueSnackbar(`${t('Upload fail')}`, {
                            variant: 'error',
                        })
                        setopen(false)
                    }
                })
                .catch((error) => {
                    console.log('handleVerify() error:', error)
                    setopen(false)
                    enqueueSnackbar(`${t('Upload fail')}`, {
                        variant: 'error',
                    })
                })
        })
    }

    const renderProofFileInfo = (files) => {
        const file = files[0]
        return (
            <span id="uploaded-off-chain-proof">
                {file.name} - {file.size} bytes
            </span>
        )
    }

    const handleVerifyRawData = (data) => {
        console.log('handleVerifyRawData() start', { data })
        let tempData = data
        tempData['verifyProcessStatus'] = verifyProcessStatusConstant.pending
        setMoreInfo({ ...data, tempData })

        // TODO verify raw data with api
        setTimeout(() => {
            let tempData = data
            let cmdObject
            try {
                cmdObject = JSON.parse(data.cmd)
            } catch (error) {
                console.log('parse cmd error', error)
            }
            tempData['txHashBaseUrl'] = getTxHashBaseUrl(detailData.evmEnv)
            tempData['rawDataVerifyResult'] = {}
            tempData['rawDataVerifyResult']['fileName'] = cmdObject.fileName
            tempData['rawDataVerifyResult']['fileHash'] = cmdObject.fileHash
            tempData['rawDataVerifyResult']['verifyResult'] = 'PASS' //FIXME verify by api
            tempData['verifyProcessStatus'] = verifyProcessStatusConstant.complete

            setMoreInfo({ ...data, tempData })
            setMoreInfoModalOpen(true)
            enqueueSnackbar(t('Verify success!'), { variant: 'success' })
        }, 3000)
    }

    const handleDownloadRawData = (info) => {
        console.log('handleDownloadRawData() start', { info })
        let content = JSON.stringify(info.cmd)
        let link = document.createElement('a')
        let blob = new Blob([content])
        let downloadUrl = URL.createObjectURL(blob)
        console.log('downloadUrl: ', downloadUrl)
        link.href = downloadUrl
        var fileName = `attested-raw-data-${info.clearanceOrder}-${info.indexValue}.json`
        link.download = fileName
        link.target = '_blank'
        link.click()
    }

    const handleMoreInfoModalOpen = (data) => {
        setMoreInfoModalOpen(true)
        const txHashBaseUrl = getTxHashBaseUrl(detailData.evmEnv)
        setMoreInfo({ ...data, txHashBaseUrl })
    }

    const handleMoreInfoModalClose = () => {
        setMoreInfoModalOpen(false)
    }

    return (
        <Box className={classes.container} px={2} py={3}>
            <Typography variant="h5" color="textPrimary">
                {t('Verify Data Integrity')}
            </Typography>
            <Hidden only={['xs']}>
                {isUploader && (
                    <UploadSection
                        maxFileSize={maxFileSize}
                        handleVerify={handleVerify}
                        renderProofFileInfo={renderProofFileInfo}
                    />
                )}
            </Hidden>
            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                {isUploader && (
                    <RwdUploadSection
                        maxFileSize={maxFileSize}
                        handleVerify={handleVerify}
                        renderProofFileInfo={renderProofFileInfo}
                    />
                )}
            </Hidden>
            {!isUploader && (
                <DetectedSection
                    isVerifyDone={detailData && Object.keys(detailData).length !== 0}
                    proofTokenInfo={proofTokenInfo}
                />
            )}
            <Box mt={4}>
                {detailData && (
                    <ProofVerificationDetailSection
                        detailData={detailData}
                        handleVerifyRawData={handleVerifyRawData}
                        handleDownloadRawData={handleDownloadRawData}
                        handleMoreInfoModalOpen={handleMoreInfoModalOpen}
                        handleMoreInfoModalClose={handleMoreInfoModalClose}
                        moreInfo={moreInfo}
                        modalOpen={moreInfoModalOpen}
                        txHash={proofTokenInfo.txHash}
                    />
                )}
            </Box>
            <Backdrop className={classes.backdrop} open={open} transitionDuration={{ exit: 500 }}>
                <Box width="100%" mt={3} className={classes.progressContainer}>
                    <ProgressBar activeStep={activeStep} setActiveStep={setActiveStep} />
                </Box>
            </Backdrop>
        </Box>
    )
}

export default ProofVerificationPage
