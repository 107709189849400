import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CustomAlert } from '../../../../style/CustomAlert'

const useStyles = makeStyles((theme) => ({
    input: {
        display: 'none',
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
}))

const RwdUploadSection = ({ maxFileSize, handleVerify, renderProofFileInfo }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [proofFile, setProofFile] = useState([])
    const onProofUpload = (event) => {
        const uploadProof = event.target.files[0]
        event.target.value = ''
        console.log('uploadProof=', uploadProof)
        if (
            uploadProof.name.slice(uploadProof.name.lastIndexOf('.')) === '.json' ||
            uploadProof.name.slice(uploadProof.name.lastIndexOf('.')) === '.itm'
        ) {
            if (uploadProof.size <= maxFileSize) {
                setProofFile([uploadProof])
                handleVerify([uploadProof])
            } else {
                enqueueSnackbar(`${t('Maximum file upload size is 1GB')}`, {
                    variant: 'warning',
                })
            }
        } else {
            enqueueSnackbar(`${t('Upload off-chain proof file name extension need to be .json or .itm')}`, {
                variant: 'warning',
            })
        }
    }

    return (
        <Box mt={3}>
            <Typography color="textPrimary" variant="body1">
                {t('Upload')} ITM Proof Token
            </Typography>
            <Button
                variant="contained"
                component="label"
                color="secondary"
                startIcon={<CloudUploadIcon />}
                className={classes.marginTop}
            >
                {t('Upload')}
                <input className={classes.input} id="upload-proof-button" type="file" onChange={onProofUpload} />
            </Button>
            {proofFile[0] && (
                <Box my={4}>
                    <Typography color="textPrimary" component={'div'} variant="body1">
                        {t('Uploaded')} ITM Proof Token
                    </Typography>
                    <CustomAlert id="uploaded-proof-info" variant="outlined" severity="info">
                        <Typography variant="body2">{renderProofFileInfo(proofFile)}</Typography>
                    </CustomAlert>
                </Box>
            )}
        </Box>
    )
}

RwdUploadSection.propTypes = {
    maxFileSize: PropTypes.number.isRequired,
    handleVerify: PropTypes.func.isRequired,
    renderProofFileInfo: PropTypes.func.isRequired,
}

export default RwdUploadSection
