import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { CustomAlert } from '../../../../style/CustomAlert'
import { uploaderBgcolor } from '../../../../style/colorStyle'

const useStyles = makeStyles((theme) => ({
    uploadFile: {
        height: '280px',
        backgroundColor: uploaderBgcolor,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.text.primary,
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: theme.shape.borderRadius,
    },
    uploadIcon: {
        fontSize: theme.typography.h2.fontSize,
    },
    input: {
        display: 'none',
    },
    marginTop: {
        marginTop: theme.spacing(1),
    },
}))

const UploadSection = ({ maxFileSize, handleVerify, renderProofFileInfo }) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const { enqueueSnackbar } = useSnackbar()

    const [cursor, setCursor] = useState('')
    const cursorStyle = {
        cursor: cursor,
    }

    const onDrop = useCallback((acceptedFiles) => {
        if (acceptedFiles.length === 1) {
            console.log('acceptedFiles: ', acceptedFiles)
            handleVerify(acceptedFiles)
        } else if (acceptedFiles.length > 1) {
            enqueueSnackbar(`${t('You can only upload one file')}`, {
                variant: 'warning',
            })
        }
    }, [])

    const onDropRejected = () => {
        enqueueSnackbar(`${t('Maximum file upload size is 1GB')}`, {
            variant: 'warning',
        })
    }
    const { getRootProps, getInputProps, acceptedFiles } = useDropzone({ onDrop, onDropRejected, maxFileSize })

    return (
        <>
            <Box
                mt={2}
                style={cursorStyle}
                onMouseOver={() => {
                    setCursor('pointer')
                }}
                onMouseLeave={() => {
                    setCursor('')
                }}
            >
                <div {...getRootProps()}>
                    <input id="proof-uploader" {...getInputProps()} multiple={false} />
                    {
                        <div className={classes.uploadFile}>
                            <Box py={1}>
                                <CloudUploadIcon className={classes.uploadIcon} />
                            </Box>
                            <Typography variant="body1">
                                {t('Choose or drop your ITM Proof Token to upload')}
                            </Typography>
                        </div>
                    }
                </div>
            </Box>
            {acceptedFiles.length === 1 && (
                <Box mt={3} mb={4}>
                    <Typography color="textPrimary" component={'div'} variant="h6">
                        {t('Uploaded')} ITM Proof Token
                    </Typography>
                    <CustomAlert id="uploaded-proof-info" variant="outlined" severity="info">
                        <Typography variant="body1">{renderProofFileInfo(acceptedFiles)}</Typography>
                    </CustomAlert>
                </Box>
            )}
        </>
    )
}

UploadSection.propTypes = {
    maxFileSize: PropTypes.number.isRequired,
    handleVerify: PropTypes.func.isRequired,
    renderProofFileInfo: PropTypes.func.isRequired,
}

export default UploadSection
