import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles, LinearProgress, Typography } from '@material-ui/core'
import classNames from 'classnames'

const useStyles = makeStyles((theme) => ({
    stepper: {
        width: '100%',
        padding: 0,
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    step: {
        width: '100%',
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column-reverse',
        },
    },
    stepIndex: {
        width: 25,
        height: 25,
        borderRadius: '50%',
        backgroundColor: theme.palette.grey[300],
        color: theme.palette.grey[600],
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stepLabel: {
        color: theme.palette.common.white,
        margin: 0,
    },
    labelContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            margin: theme.spacing(4, 0, 7),
        },
    },
    linearProgress: {
        margin: theme.spacing(0, 1),
        flexGrow: 1,
        position: 'absolute',
        top: 12,
        left: 'calc(-50% + 20px)',
        right: 'calc(50% + 20px)',
        [theme.breakpoints.down('xs')]: {
            width: 90,
            marginBottom: theme.spacing(4),
            position: 'relative',
            top: 0,
            left: 0,
            right: 0,
            transform: 'rotate(90deg)',
        },
    },
    currentStep: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        transition: 'color .8s linear',
    },
    done: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        transition: 'color .8s linear',
    },
}))

const CustomStepper = ({ steps, activeStep, progress }) => {
    const classes = useStyles()

    const StepContent = ({ index }) => {
        return <Typography variant="body2">{index + 1}</Typography>
    }

    const renderStep = (label, key) => {
        let step = key
        const done = key < activeStep
        const currentStep = key === activeStep
        const stepClasses = classNames({
            [classes.stepIndex]: true,
            [classes.currentStep]: currentStep,
            [classes.done]: done,
        })

        return (
            <li className={classes.step} key={key}>
                <div className={classes.labelContainer}>
                    <span className={stepClasses}>
                        <StepContent index={key} />
                    </span>
                    <Typography variant="body2" className={classes.stepLabel}>
                        {label}
                    </Typography>
                </div>
                {!!key && (
                    <LinearProgress
                        variant="determinate"
                        value={(activeStep + 1 === step && progress) || (activeStep >= step && 100)}
                        classes={{ root: classes.linearProgress }}
                    />
                )}
            </li>
        )
    }

    return <ul className={classes.stepper}>{steps.map(renderStep, { activeStep, progress })}</ul>
}

CustomStepper.propTypes = {
    steps: PropTypes.array.isRequired,
    step: PropTypes.number,
    activeStep: PropTypes.number.isRequired,
    progress: PropTypes.number,
    index: PropTypes.number,
}

export default CustomStepper
