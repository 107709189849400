import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import i18next from 'i18next'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { CustomAlert } from '../../../../style/CustomAlert'

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        alignItems: 'flex-end',
        marginTop: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
        },
    },
    downloadButton: {
        marginRight: theme.spacing(1),
    },
    detectedHint: {
        color: '#d2d2d2',
        margin: theme.spacing(1, 0),
    },
    checkIcon: {
        marginRight: theme.spacing(1),
    },
}))

const DetectedSection = ({ isVerifyDone, proofTokenInfo, detectFailInfoDic }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const onDownloadClick = () => {
        if (proofTokenInfo?.downloadFileLink) {
            proofTokenInfo.downloadFileLink.click()
        }
    }

    return (
        <Box mt={3} mb={5}>
            {detectFailInfoDic && detectFailInfoDic.isFail ? (
                <CustomAlert id="uploaded-proof-info" variant="outlined" severity="error">
                    <Typography variant="body1">
                        {i18next.t('Detected Failed Hint Message', {
                            requiredParam: detectFailInfoDic.missingInfo,
                        })}
                    </Typography>
                </CustomAlert>
            ) : (
                proofTokenInfo.vin && (
                    <>
                        <Box display="flex" alignItems="center">
                            {isVerifyDone && (
                                <>
                                    <CheckCircleIcon fontSize="small" color="secondary" className={classes.checkIcon} />
                                    <Typography color="textPrimary" variant="h6">
                                        {t('Verify done')} !
                                    </Typography>
                                </>
                            )}
                        </Box>
                        <CustomAlert id="uploaded-proof-info" variant="outlined" severity="info">
                            <Typography variant="body1">{`${t('Detected')}${t('Car VIN')} : ${
                                proofTokenInfo.vin
                            }`}</Typography>
                        </CustomAlert>
                        <Box className={classes.buttonContainer}>
                            <Button
                                startIcon={<CloudDownloadIcon />}
                                variant="contained"
                                color="secondary"
                                className={classes.downloadButton}
                                onClick={onDownloadClick}
                            >
                                {t('Download This Proof Token')}
                            </Button>
                            <Typography variant="body2" color="textPrimary" className={classes.detectedHint}>
                                {t('Or view your verify result below')}
                            </Typography>
                        </Box>
                    </>
                )
            )}
        </Box>
    )
}

DetectedSection.propTypes = {
    isVerifyDone: PropTypes.bool,
    proofTokenInfo: PropTypes.object.isRequired,
    detectFailInfoDic: PropTypes.object,
}

export default DetectedSection
