import React, { useState } from 'react'
import Cookies from 'universal-cookie'
import { useTranslation } from 'react-i18next'

import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'
import Box from '@material-ui/core/Box'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import TranslateIcon from '../../img/translate.svg'

const useStyles = makeStyles(() => ({
    language: {
        padding: 3,
        minWidth: 20,
    },
}))

const LanguageMenu = () => {
    const classes = useStyles()
    const [language, setlanguage] = useState(null)
    const [selectedIndex, setselectedIndex] = useState(0)
    const languageOpen = Boolean(language)
    const cookies = new Cookies()
    const defaultCookieLanguage = cookies.get('language')
    const [currentLanguage, setcurrentLanguage] = useState(defaultCookieLanguage ? defaultCookieLanguage : 'English')
    const { i18n } = useTranslation()

    const handleLanguageMenu = (event) => {
        setlanguage(event.currentTarget)
    }

    const handleLanguageClose = () => {
        setlanguage(null)
    }

    const handleLanguage = (event, index) => {
        const language = event.currentTarget.getAttribute('data-id')
        if (language === '中文') {
            i18n.changeLanguage('zh-TW')
            cookies.set('language', '中文', { path: '/' })
            setcurrentLanguage('中文')
        } else {
            i18n.changeLanguage('en')
            cookies.set('language', 'English', { path: '/' })
            setcurrentLanguage('English')
        }
        setlanguage(null)
        setselectedIndex(index)
    }

    const languageOptions = ['中文', 'English']
    return (
        <div>
            <Button className={classes.language} onClick={handleLanguageMenu} color="inherit">
                <img src={TranslateIcon} alt="TranslateIcon" height="20" width="20" />
                <Hidden xsDown>
                    <Box ml={1}>{currentLanguage || '中文'}</Box>
                    <ExpandMoreIcon />
                </Hidden>
            </Button>
            <Menu
                id="language"
                getContentAnchorEl={null}
                anchorEl={language}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={languageOpen}
                onClose={handleLanguageClose}
            >
                {languageOptions.map((option, index) => (
                    <MenuItem
                        key={option}
                        data-id={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleLanguage(event, index)}
                    >
                        {option}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    )
}

export default LanguageMenu
