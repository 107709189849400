import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    makeStyles,
    Typography,
    TableContainer,
    TableHead,
    Table,
    TableRow,
    TableBody,
    Paper,
    Tooltip,
    IconButton,
    TableCell,
    Hidden,
} from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import DataRow from '../../../common/VerifyListTableRow/DataRow'
import CollapseRow from '../../../common/VerifyListTableRow/NewCollapseRow'
import { CustomCell } from '../../../../style/CustomTableCell'
import { arrangeRowDataList } from '../../../../util/verifyListTableDataUtil'
import { getTxHashBaseUrl } from '../../../../util/stringUtil'
import { proofDetailSortAscFunction, proofDetailSortDescFunction } from '../../../../util/sortUtil'
import sortTypeConstant from '../../../../constant/sortTypeConstant'

const useStyles = makeStyles((theme) => ({
    marginTop: {
        marginTop: theme.spacing(1),
    },
    link: {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
    },
}))

const RwdVerifyListTable = ({
    detailData,
    handleMoreInfoModalOpen,
    handleVerifyRawData,
    handleDownloadRawData,
    sortType,
}) => {
    const classes = useStyles()
    const { t } = useTranslation()
    const txHashBaseUrl = getTxHashBaseUrl(detailData.evmEnv)

    const renderClearanceOrder = (data) => {
        return data.txHash !== null ? (
            <Tooltip title={t('proof.verification.detail.help_clearance_order')} placement="bottom-start">
                <Typography
                    id="row-co-link"
                    component="a"
                    variant="body2"
                    href={`${txHashBaseUrl}/${data.txHash}`}
                    className={classes.link}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {data.clearanceOrder}
                </Typography>
            </Tooltip>
        ) : (
            <>{data.clearanceOrder}</>
        )
    }

    const renderMoreInfo = (data) => {
        return (
            data.proofExistStatus !== 'REMOVED' && (
                <IconButton onClick={() => handleMoreInfoModalOpen(data)}>
                    <SearchIcon />
                </IconButton>
            )
        )
    }

    const renderShortIndexValue = (value) => {
        return value.substr(value.length - 2)
    }

    const [rowList, setRowList] = useState([])

    useEffect(() => {
        if (detailData.proofDetailList && detailData.proofDetailList.length > 0) {
            if (sortType === sortTypeConstant.ASC) {
                detailData.proofDetailList.sort(proofDetailSortAscFunction)
            } else {
                detailData.proofDetailList.sort(proofDetailSortDescFunction)
            }
            setRowList(arrangeRowDataList(detailData.proofDetailList))
        }
    }, [detailData, sortType])

    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table id="verify-list-table" stickyHeader aria-label="sticky table">
                    <TableHead>
                        {
                            <Hidden only={['xs']}>
                                <TableRow id="proof-verification-header-row">
                                    <TableCell>
                                        <Typography variant="body2">{t('Attestation Time')}</Typography>
                                        <Typography variant="body2" className={classes.marginTop}>
                                            {t('Type')}
                                        </Typography>
                                    </TableCell>
                                    <Hidden mdDown>
                                        <TableCell>
                                            <Typography variant="body2">{t('Attester DID')}</Typography>
                                            <Typography variant="body2" className={classes.marginTop}>
                                                {t('Attester Certificated Info')}
                                            </Typography>
                                        </TableCell>
                                    </Hidden>
                                    <TableCell>
                                        <Typography variant="body2">{t('Blockchain Detail')}</Typography>
                                        <Typography variant="body2" className={classes.marginTop}>
                                            {'Index Value'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="center">
                                        <Typography variant="body2">{t('Verify Status')}</Typography>
                                    </TableCell>
                                    <Hidden smDown>
                                        <TableCell align="center">
                                            <Typography variant="body2">{t('Verify Raw Data')}</Typography>
                                        </TableCell>
                                        <TableCell align="center">
                                            <Typography variant="body2">{t('Download Raw Data')}</Typography>
                                        </TableCell>
                                    </Hidden>
                                    <TableCell align="center">
                                        <Typography variant="body2">{t('More Info')}</Typography>
                                    </TableCell>
                                </TableRow>
                            </Hidden>
                        }
                        {
                            <Hidden only={['sm', 'md', 'lg', 'xl']}>
                                <TableRow id="verify-list-header-row">
                                    <CustomCell align="left">
                                        <Typography variant="body2">{t('Attestation Time')}</Typography>
                                        <Typography variant="body2" className={classes.marginTop}>
                                            {t('Type')}
                                        </Typography>
                                    </CustomCell>
                                    <CustomCell align="center">{t('Verify Status')}</CustomCell>
                                    <CustomCell align="center">{t('More Info')}</CustomCell>
                                </TableRow>
                            </Hidden>
                        }
                    </TableHead>
                    <TableBody>
                        {rowList.map(({ collapse, dataList }, index) => {
                            return (
                                <React.Fragment key={`verify-list-row-${index}`}>
                                    {collapse && (
                                        <CollapseRow
                                            dataArr={dataList}
                                            renderMoreInfo={renderMoreInfo}
                                            renderClearanceOrder={renderClearanceOrder}
                                            hideBottomBorder={index === rowList.length - 1}
                                        />
                                    )}
                                    {!collapse &&
                                        dataList.map((singleRowData, index) => (
                                            <DataRow
                                                key={`data-row-${index}`}
                                                data={singleRowData}
                                                renderMoreInfo={renderMoreInfo}
                                                handleVerifyRawData={handleVerifyRawData}
                                                handleDownloadRawData={handleDownloadRawData}
                                                renderClearanceOrder={renderClearanceOrder}
                                                renderShortIndexValue={renderShortIndexValue}
                                            />
                                        ))}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

RwdVerifyListTable.propTypes = {
    detailData: PropTypes.object.isRequired,
    handleMoreInfoModalOpen: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
    sortType: PropTypes.string.isRequired,
}

export default RwdVerifyListTable
