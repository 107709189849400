import { withStyles } from '@material-ui/core/styles'
import Alert from '@material-ui/lab/Alert'
import { uploaderBgcolor, errorAlertBgcolor } from './colorStyle'

const CustomAlert = withStyles((theme) => ({
    root: {
        alignItems: 'center',
        padding: theme.spacing(2),
        marginTop: theme.spacing(1),
        wordBreak: 'break-word',
    },
    outlinedInfo: {
        color: theme.palette.text.primary,
        backgroundColor: uploaderBgcolor,
        border: `1px solid ${theme.palette.secondary.light}`,
        borderRadius: theme.shape.borderRadius,
        '& .MuiAlert-icon': {
            color: theme.palette.text.primary,
        },
    },
    outlinedError: {
        color: theme.palette.text.primary,
        backgroundColor: errorAlertBgcolor,
        border: `1px solid ${theme.palette.error.main}`,
        borderRadius: theme.shape.borderRadius,
        '& .MuiAlert-icon': {
            color: theme.palette.text.primary,
        },
    },
}))(Alert)

export { CustomAlert }
