import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
    makeStyles,
    Box,
    Typography,
    Tooltip,
    TableContainer,
    Table,
    TableRow,
    TableCell,
    TableBody,
    Paper,
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import { getContractAddressUrl, renderTime } from '../../../../util/stringUtil'

const useStyles = makeStyles((theme) => ({
    contentTypo: {
        marginTop: theme.spacing(1),
        wordBreak: 'break-all',
    },
    link: {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
        marginRight: theme.spacing(1),
        wordBreak: 'break-all',
    },
    checkIcon: {
        color: theme.palette.secondary.light,
    },
    marginLeft: {
        marginLeft: theme.spacing(1),
    },
}))

const RwdVerifyDetailTable = ({ detailData }) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const contractAddressUrl = getContractAddressUrl(detailData.evmEnv, detailData.contractAddress)

    const renderResult = (result, verifyResultDescription) => {
        if (result === 'PASS') {
            return (
                <>
                    <CheckIcon className={classes.checkIcon} />
                    <Typography variant="body2" className={classes.marginLeft}>
                        {t(`${verifyResultDescription}`)}
                    </Typography>
                </>
            )
        } else {
            return (
                <>
                    <CloseIcon color="error" />
                    <Typography variant="body2" className={classes.marginLeft}>
                        {t(`${verifyResultDescription}`)}
                    </Typography>
                </>
            )
        }
    }

    return (
        <Paper>
            <TableContainer component={Paper}>
                <Table id="verify-detail-table">
                    <TableBody>
                        <TableRow id="overall-verify-result">
                            <TableCell>
                                <Typography variant="body2">{t('Overall Verify Result')}</Typography>
                                <Box display="flex" alignItems="center" className={classes.contentTypo}>
                                    {renderResult(detailData.verifyResult, detailData.verifyResultDescription)}
                                </Box>
                            </TableCell>
                        </TableRow>
                        {detailData.verifyResultDescription !== 'Contract connection error' && (
                            <TableRow id="verify-count-row">
                                <TableCell>
                                    <Typography variant="body2">{t('Verify Count')}</Typography>
                                    <Typography variant="body2" className={classes.contentTypo}>
                                        {t('Total')}: {detailData.totalCount},{t('Pass')}: {detailData.successCount},
                                        {t('Modified')}: {detailData.modifiedCount}
                                        {detailData.queryType !== 'LOCATOR' && (
                                            <>
                                                , {t('Added')}: {detailData.addedCount},{t('Removed')}:{' '}
                                                {detailData.removedCount}
                                            </>
                                        )}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow id="verify-time-row">
                            <TableCell>
                                <Typography variant="body2">{t('Verify Time')}</Typography>
                                <Typography variant="body2" className={classes.contentTypo}>
                                    {renderTime(detailData.uploadTimestamp)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        {detailData.vin && (
                            <TableRow id="car-vin-row">
                                <TableCell>
                                    <Typography variant="body2">{t('Car VIN')}</Typography>
                                    <Typography variant="body2" className={classes.contentTypo}>
                                        {detailData.vin}
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                        <TableRow id="contract-address-row">
                            <TableCell>
                                <Typography variant="body2">{t('Contract Address')}</Typography>
                                <Box display="flex" alignItems="center" className={classes.contentTypo}>
                                    <Typography
                                        component="a"
                                        variant="body2"
                                        href={contractAddressUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        className={classes.link}
                                    >
                                        {detailData.contractAddress}
                                    </Typography>
                                    <Tooltip
                                        title={t('proof.verification.detail.help_contract_address')}
                                        placement="right"
                                        aria-label="contractAddress"
                                    >
                                        <HelpIcon fontSize="small" color="secondary" />
                                    </Tooltip>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    )
}

RwdVerifyDetailTable.propTypes = {
    detailData: PropTypes.object.isRequired,
}

export default RwdVerifyDetailTable
