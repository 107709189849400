import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import Collapse from '@material-ui/core/Collapse'
import CircularProgress from '@material-ui/core/CircularProgress'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import GavelIcon from '@material-ui/icons/Gavel'
import GetAppIcon from '@material-ui/icons/GetApp'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import CloseRoundedIcon from '@material-ui/icons/CloseRounded'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'

import VerifyStatus from '../../../common/VerifyStatus'
import proofExistenceType from '../../../../constant/proofExistenceType'
import verifyProcesStatusConstant from '../../../../constant/verifyProcessStatusConstant'
import {
    getAttesterDID,
    getAttestType,
    getMIHAttesterInfo,
    renderCmd,
    renderExistence,
    renderTime,
} from '../../../../util/stringUtil'

const useStyles = makeStyles((theme) => ({
    modal: {
        backgroundColor: theme.palette.background.default,
        position: 'absolute',
        overflowY: 'auto',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3, 3, 4),
        maxHeight: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '80%',
        },
        [theme.breakpoints.up('md')]: {
            width: '50%',
            maxHeight: '75%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '60%',
            maxWidth: 900,
            maxHeight: '80%',
        },
    },
    cmd: {
        whiteSpace: 'pre-wrap',
        wordBreak: 'break-all',
        marginTop: theme.spacing(1),
    },
    certificatePass: {
        marginLeft: theme.spacing(1),
    },
    certificateFail: {
        color: theme.palette.text.hint,
        marginLeft: theme.spacing(1),
    },
    marginRight: {
        marginRight: theme.spacing(1),
    },
    link: {
        color: theme.palette.secondary.light,
        textDecoration: 'none',
    },
    circularProgress: {
        color: theme.palette.text.primary,
    },
    checkIcon: {
        color: theme.palette.secondary.light,
    },
    marginLeft: {
        marginLeft: theme.spacing(0.5),
    },
    verifyResultLoading: {
        color: theme.palette.secondary.light,
    },
    contentTypo: {
        marginTop: theme.spacing(1),
        wordBreak: 'break-all',
    },
    outterBox: { border: '1px solid #56F7FD', borderRadius: '8px', color: 'white' },
    infoBox: { borderBottom: '1px solid #56F7FD', padding: '8px', color: 'white' },
    bottomInfoBox: { padding: '8px', color: 'white' },
    rawDataBox: {
        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            alignItems: 'center',
        },
    },
    downloadRawDataBox: {
        [theme.breakpoints.down('sm')]: {
            marginTop: '8px',
        },
    },
}))

const getModalStyle = () => {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    }
}

const RwdMoreInfoModal = ({ moreInfo, close, handleVerifyRawData, handleDownloadRawData }) => {
    const { t } = useTranslation()
    const classes = useStyles()
    const [modalStyle] = useState(getModalStyle)
    const [infoExpand, setInfoExpand] = useState(true)
    const [resultExpand, setResultExpand] = useState(true)

    // get data
    const attesterDID = getAttesterDID(moreInfo.cmd)
    const attesterInfo = getMIHAttesterInfo(moreInfo.cmd)

    return (
        <div id="more-info-modal" style={modalStyle} className={classes.modal}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Typography variant="h6" color="textPrimary">
                    {t('More Info')}
                </Typography>
                <IconButton onClick={close}>
                    <CloseRoundedIcon />
                </IconButton>
            </Box>
            {moreInfo.existenceType === proofExistenceType.exist && (
                <Box mt={1} mb={3} className={classes.rawDataBox}>
                    {!moreInfo.rawDataVerifyResult && (
                        <Button
                            startIcon={
                                moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ? (
                                    <CircularProgress size={18} className={classes.circularProgress} />
                                ) : (
                                    <GavelIcon />
                                )
                            }
                            variant="contained"
                            color="secondary"
                            className={classes.marginRight}
                            onClick={() => handleVerifyRawData(moreInfo)}
                        >
                            {t('Verify Raw Data')}
                        </Button>
                    )}
                    <Box className={classes.downloadRawDataBox}>
                        <Button
                            startIcon={<GetAppIcon />}
                            variant="contained"
                            color="secondary"
                            onClick={() => handleDownloadRawData(moreInfo)}
                        >
                            {t('Download Raw Data')}
                        </Button>
                    </Box>
                </Box>
            )}
            {(moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending ||
                moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete) && (
                <Box mb={4}>
                    <Box display="flex" alignItems="center" mb={1}>
                        <Typography variant="body1" color="textPrimary">
                            {t('Raw Data Verify Result')}
                        </Typography>
                        <IconButton size="small" onClick={() => setResultExpand((prev) => !prev)}>
                            {resultExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </IconButton>
                    </Box>
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.pending && (
                        <Box py={1} display="flex" justifyContent="center">
                            <CircularProgress size={24} className={classes.verifyResultLoading} />
                        </Box>
                    )}
                    {moreInfo.verifyProcessStatus === verifyProcesStatusConstant.complete && (
                        <Collapse in={resultExpand} timeout="auto" unmountOnExit>
                            <Box className={classes.outterBox}>
                                {moreInfo.cmd.includes('fileHash') ? (
                                    <>
                                        {moreInfo.rawDataVerifyResult.fileName && (
                                            <Box className={classes.infoBox} id="file-name-row">
                                                <Typography variant="body2">{t('File Name')}</Typography>
                                                <Typography variant="body2" className={classes.contentTypo}>
                                                    {moreInfo.rawDataVerifyResult.fileName}
                                                </Typography>
                                            </Box>
                                        )}
                                        <Box className={classes.infoBox} id="file-hash-row">
                                            <Typography variant="body2">{t('File Hash')}</Typography>
                                            <Typography variant="body2" className={classes.contentTypo}>
                                                {moreInfo.rawDataVerifyResult.fileHash}
                                            </Typography>
                                        </Box>
                                    </>
                                ) : (
                                    <Box className={classes.infoBox} id="raw-data-row">
                                        <Typography variant="body2">{t('Raw Data Content')}</Typography>
                                        <Typography variant="body2" className={classes.cmd}>
                                            {renderCmd(moreInfo.cmd)}
                                        </Typography>
                                    </Box>
                                )}
                                <Box className={classes.bottomInfoBox} id="raw-data-verify-status-row">
                                    <Typography variant="body2">{t('Raw Data Verify Status')}</Typography>
                                    {moreInfo.rawDataVerifyResult.verifyResult === 'PASS' && (
                                        <Box display="flex" alignItems="center" className={classes.contentTypo}>
                                            <CheckIcon className={classes.checkIcon} />
                                            <Typography variant="body2" className={classes.marginLeft}>
                                                {t('Verify success')}
                                            </Typography>
                                        </Box>
                                    )}
                                    {moreInfo.rawDataVerifyResult.verifyResult === 'FAILED' && (
                                        <Box display="flex" alignItems="center" className={classes.contentTypo}>
                                            <CloseIcon color="error" />
                                            <Typography variant="body2" className={classes.marginLeft}>
                                                {t('Verify Fail')}
                                            </Typography>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Collapse>
                    )}
                </Box>
            )}
            <Box display="flex" alignItems="center" mb={1}>
                <Typography variant="body1" color="textPrimary">
                    {t('Detail Info')}
                </Typography>
                <IconButton size="small" onClick={() => setInfoExpand((prev) => !prev)}>
                    {infoExpand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={infoExpand} timeout="auto" unmountOnExit>
                <Box className={classes.outterBox}>
                    {moreInfo.cmd && moreInfo.cmd.includes('type') && (
                        <Box className={classes.infoBox} id="type-row">
                            <Typography variant="body2">{t('Type')}</Typography>
                            <Typography variant="body2" className={classes.contentTypo}>
                                {t(getAttestType(moreInfo.cmd))}
                            </Typography>
                        </Box>
                    )}
                    {moreInfo.existenceType === proofExistenceType.exist && (
                        <>
                            {attesterDID && attesterDID !== '' && (
                                <Box className={classes.infoBox} id="attester-did-row">
                                    <Typography variant="body2">{t('Attester DID')}</Typography>
                                    <Typography variant="body2" className={classes.contentTypo}>
                                        {attesterDID}
                                    </Typography>
                                </Box>
                            )}
                            {attesterInfo && attesterInfo !== '' && (
                                <Box className={classes.infoBox} id="attester-certificated-info-row">
                                    <Typography variant="body2">{t('Attester Certificated Info')}</Typography>
                                    <Box display="flex" alignItems="center" className={classes.contentTypo}>
                                        <Typography variant="body2">{t(attesterInfo)}</Typography>
                                        <CheckCircleIcon
                                            fontSize="small"
                                            color="secondary"
                                            className={classes.certificatePass}
                                        />
                                    </Box>
                                </Box>
                            )}
                        </>
                    )}
                    <Box className={classes.infoBox} id="blockchain-detail-row">
                        <Typography variant="body2">{t('Blockchain Detail')}</Typography>
                        {moreInfo.txHash !== null ? (
                            <Tooltip
                                title={t('proof.verification.detail.help_clearance_order')}
                                placement="bottom-start"
                                className={classes.contentTypo}
                            >
                                <Typography
                                    id="more-info-co-link"
                                    component="a"
                                    variant="body2"
                                    href={`${moreInfo.txHashBaseUrl}/${moreInfo.txHash}`}
                                    className={classes.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {moreInfo.clearanceOrder}
                                </Typography>
                            </Tooltip>
                        ) : (
                            <Typography variant="body2" className={classes.contentTypo}>
                                {moreInfo.clearanceOrder}
                            </Typography>
                        )}
                    </Box>
                    <Box className={classes.infoBox} id="index-value-row">
                        <Typography variant="body2">Index Value</Typography>
                        <Typography variant="body2" className={classes.contentTypo}>
                            {moreInfo.indexValue}
                        </Typography>
                    </Box>
                    <Box className={classes.infoBox} id="existence-row">
                        <Typography variant="body2">{t('Existence')}</Typography>
                        <Typography variant="body2" className={classes.contentTypo}>
                            {renderExistence(moreInfo.existenceType)}
                        </Typography>
                    </Box>
                    <Box className={classes.infoBox} id="verify-status-row">
                        <Typography variant="body2">{t('Verify Status')}</Typography>
                        <Box className={classes.contentTypo}>
                            <VerifyStatus status={moreInfo.proofExistStatus} iconOnly={false} />
                        </Box>
                    </Box>
                    {moreInfo.existenceType === proofExistenceType.exist && (
                        <>
                            <Box className={classes.infoBox} id="attestation-time-row">
                                <Typography variant="body2">{t('Attestation Time')}</Typography>
                                <Typography variant="body2" className={classes.contentTypo}>
                                    {renderTime(moreInfo.receiptTimestamp)}
                                </Typography>
                            </Box>
                            {moreInfo.merkleProofRootHash !== null && (
                                <Box className={classes.infoBox} id="root-hash-row">
                                    <Typography variant="body2">Root Hash</Typography>
                                    <Typography variant="body2" className={classes.contentTypo}>
                                        {moreInfo.merkleProofRootHash}
                                    </Typography>
                                </Box>
                            )}
                            <Box className={classes.bottomInfoBox} id="attested-fields-row">
                                <Typography variant="body2">{t('Attested Fields')}</Typography>
                                <Typography variant="body2" className={classes.cmd}>
                                    {renderCmd(moreInfo.cmd)}
                                </Typography>
                            </Box>
                        </>
                    )}
                </Box>
            </Collapse>
        </div>
    )
}

RwdMoreInfoModal.propTypes = {
    moreInfo: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
}

export default RwdMoreInfoModal
